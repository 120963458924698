<template>
    <div id="game">
        <header>
            <div class="header-container">
                <div class="header-left">
                    <div class="icon-btn help" @click="helpOpened = true" title="Help">
                        <img class="icon" src="/icons/help.svg" alt="Aide" />
                    </div>
                </div>
                <div class="game-title"><!--MORDLE-->
                    <div class="letter correct">U</div>
                    <div class="letter incorrect">E</div>
                    <div class="space"></div>
                    <div class="letter partial">D</div>
                    <div class="letter incorrect">O</div>
                    <div class="letter incorrect">U</div>
                    <div class="space"></div>
                    <div class="letter incorrect">話</div>
                    <div class="letter incorrect">圖</div>
                    <div class="letter incorrect">潮</div>
                    <div class="letter incorrect">州</div>
                    <div class="letter incorrect">話</div>
                </div>
                <div class="header-right">
                    <div class="icon-btn stats" @click="statsOpened = true" title="Statistiques">
                        <img class="icon" src="/icons/stats.svg" alt="Statistiques" />
                    </div>
                    <div class="icon-btn settings" @click="settingsOpened = true" title="Paramètres">
                        <img class="icon" src="/icons/settings.svg" alt="Paramètres" />
                    </div>
                </div>
            </div>
        </header>
        <main>
            <transition name="fade">
                <div class="error" v-if="error">{{ error }}</div>
            </transition>

          <div class="grid" v-if="attempts[this.currentAttempt - 1] !== undefined">
            <h2>{{attempts[this.currentAttempt - 1].join('')}} {{mapChineses.get(attempts[this.currentAttempt - 1].join(''))}}</h2>
            <h2>{{mapDefinitions.get(attempts[this.currentAttempt - 1].join(''))}}</h2>
          </div>
            <div class="grid">
                <div class="attempt" v-for="attempt, indexA in attempts" :key="indexA" :class="{ shake: error && indexA === currentAttempt - 1 }">
                    <LetterContainer
                        :letter="attempts[indexA][indexL]"
                        :color="results[indexA][indexL]"
                        :placement="letter"
                        :animate="animateLetter"
                        :colorBlindMode="colorBlindMode"
                        v-for="letter, indexL in NB_LETTERS"
                        :key="letter" />
                </div>
            </div>
            <div class="keyboard">
                <div class="keyboard-line" v-for="line, index in keyboard.content" :key="index">
                    <Key
                        :keyContent="key"
                        :color="getKeyColor(key)"
                        :colorBlindMode="colorBlindMode"
                        v-for="key in line"
                        :key="key"
                        :keyboardName="keyboard.name"
                        @update:handleClick="handleKeyClick($event)"
                    />
                </div>
            </div>

            <transition name="fadeup">
                <div class="help-modal" v-if="helpOpened">
                    <div class="help-modal-content">
                        <div class="close-btn" @click="helpOpened = false">
                            <img class="icon" src="/icons/close.svg" alt="Fermer" />
                        </div>
                        <h2>How to play ?</h2>
                        <div class="help-content">
                            <p>This game is based on the same concept as <a href="https://www.powerlanguage.co.uk/wordle/" target="_blank">Wordle</a>, but in teochew.</p>
                            <p>Each guess must be a valid 6 letter word in 7 tries. Hit the enter button to submit.</p>
                            <p>After each guess, the color of the tiles will change to show how close your guess was to the word.</p>
                            <div class="help-exemple">
                                <div class="help-word">
                                    <div class="help-letter-container correct" :class="{ 'color-blind': colorBlindMode }">
                                        G
                                    </div>
                                    <div class="help-letter-container">
                                        A
                                    </div>
                                    <div class="help-letter-container">
                                        M
                                    </div>
                                    <div class="help-letter-container">
                                        S
                                    </div>
                                    <div class="help-letter-container">
                                        I
                                    </div>
                                    <div class="help-letter-container">
                                        A
                                    </div>
                                </div>
                                <p>The letter <span class="correct" :class="{ 'color-blind': colorBlindMode }">G</span> is part of the word, at the right place.</p>
                                <div class="help-word">
                                    <div class="help-letter-container">
                                        G
                                    </div>
                                    <div class="help-letter-container">
                                        U
                                    </div>
                                    <div class="help-letter-container partial" :class="{ 'color-blind': colorBlindMode }">
                                        E
                                    </div>
                                    <div class="help-letter-container">
                                        D
                                    </div>
                                    <div class="help-letter-container">
                                        I
                                    </div>
                                    <div class="help-letter-container">
                                        O
                                    </div>
                                </div>
                                <p>The letter <span class="partial" :class="{ 'color-blind': colorBlindMode }">E</span> is part of the word, but not in the right place.</p>
                                <div class="help-word">
                                    <div class="help-letter-container">
                                        T
                                    </div>
                                    <div class="help-letter-container">
                                        A
                                    </div>
                                    <div class="help-letter-container">
                                        K
                                    </div>
                                    <div class="help-letter-container">
                                        J
                                    </div>
                                    <div class="help-letter-container incorrect" :class="{ 'color-blind': colorBlindMode }">
                                        E
                                    </div>
                                    <div class="help-letter-container">
                                        U
                                    </div>
                                </div>
                                <p>The letter <span>E</span> is not part of the word.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
            <transition name="fadeup">
                <div class="endgame-modal" v-if="statsOpened">
                    <div class="endgame-modal-content" v-bind:class="{ 'finished' : finished}">
                        <div class="close-btn" @click="statsOpened = false">
                            <img class="icon" src="/icons/close.svg" alt="Fermer" />
                        </div>
                        <h2>Statistics</h2>
                        <div class="stats">
                            <div class="stats-content">
                                <div class="stats-line">
                                    <div class="stats-item games-played">
                                        <p class="stat-item-figure">{{ userResults.nbGames }}</p>
                                        <p class="stat-item-label">Games</p>
                                    </div>
                                    <div class="stats-item win-rate">
                                        <p class="stat-item-figure">{{ Math.round((userResults.nbGames > 0 ? userResults.nbWins / userResults.nbGames : 0) * 100) }}</p>
                                        <p class="stat-item-label">Win (%)</p>
                                    </div>
                                </div>
                                <div class="stats-line">
                                    <div class="stats-item current-streak">
                                        <p class="stat-item-figure">{{ userResults.currentStreak }}</p>
                                        <p class="stat-item-label">Current streak</p>
                                    </div>
                                    <div class="stats-item current-streak">
                                        <p class="stat-item-figure">{{ userResults.bestStreak }}</p>
                                        <p class="stat-item-label">Best streak</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h2>Performances</h2>
                        <div class="graph">
                            <div class="graph-content">
                                <div class="graph-item" v-for="attempt in NB_ATTEMPTS + 1" :key="attempt">
                                    <div class="attempt-number" v-if="attempt <= NB_ATTEMPTS">{{ attempt }}</div>
                                    <div class="attempt-skull" v-else>
                                        <img class="icon" src="/icons/skull.svg" alt="Mort" />
                                    </div>
                                    <div class="attempt-stat">
                                        <div class="attempt-bar" :class="{ best: finished && attempt === currentAttempt}" :style="{ width: `${getAttemptStatPercent(attempt)}%`}">{{ getAttemptStat(attempt) }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="soluce" v-if="finished">
                            <div class="subtitle">The word was</div>
                            <h2>{{ wordOfTheDay }}</h2>
                            <h3>meaning</h3>
                            <h2>{{mapDefinitions.get(wordOfTheDay)}} {{mapChineses.get(wordOfTheDay)}}</h2>
                            <div class="ctas">
                                <div class="btn sh4re-btn-anti-adblock" @click="sh4reAntiAdblock">
                                    <img class="icon" src="/icons/copy.svg" />
                                    <p>{{resultsCopied ? 'Copied !' : 'Share'}}</p>
                                </div>
                            </div>
                          <div class="download-link ctas" >
                            <div class="download-btn">
                              <a :href="`https://play.google.com/store/apps/details?id=com.tcknow.whattcsay3&hl=fr&gl=US`" target="_blank" class="btn definition-btn">
                                <img class="download" src="/icons/google-play.svg" />
                              </a>
                            </div>
                            <div class="download-btn">
                              <a :href="`https://apps.apple.com/fr/app/whattcsay3/id1551477228`" target="_blank" class="btn definition-btn">
                                <img class="download" src="/icons/mac-os.svg" />
                              </a>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div class="modal-footer" v-if="finished">
                        <div class="next-in">Next word in</div>
                        <div class="time">{{ countdownToNextWord }}</div>
                    </div>
                </div>
            </transition>
            <transition name="fadeup">
                <div class="settings-modal" v-if="settingsOpened">
                    <div class="settings-modal-content">
                        <div class="close-btn" @click="settingsOpened = false">
                            <img class="icon" src="/icons/close.svg" alt="Fermer" />
                        </div>
                        <h2>Parameters</h2>
                        <div class="settings-content">
                            <div class="settings-item setting-toggle">
                                <h3>Shared link</h3>
                                <div class="toggle-button" @click="sharedLink = !sharedLink" :class="{ activated: sharedLink }">
                                    <div class="toggle"></div>
                                </div>
                            </div>
                            <div class="settings-item setting-toggle">
                                <h3>Color blind mode</h3>
                                <div class="toggle-button" @click="colorBlindMode = !colorBlindMode" :class="{ activated: colorBlindMode }">
                                    <div class="toggle"></div>
                                </div>
                            </div>
                            <div class="settings-item setting-button">
                                <h3>Keyboard</h3>
                                <div class="buttons">
                                    <button :class="{ selected: keyboard.name === KEYBOARD_AZERTY.name }" @click="keyboard = KEYBOARD_AZERTY">AZERTY</button>
                                    <button :class="{ selected: keyboard.name === KEYBOARD_QWERTY.name }" @click="keyboard = KEYBOARD_QWERTY">QWERTY</button>
                                    <button :class="{ selected: keyboard.name === KEYBOARD_QWERTZ.name }" @click="keyboard = KEYBOARD_QWERTZ">QWERTZ</button>
                                </div>
                            </div>
                            <div class="credits">
                                <h2>Credits</h2>
                                <p>
                                    Game developped by <a href="https://twitter.com/NonoDarko" target="_blank">@NonoDarko</a>.
                                </p>
                                <p>
                                    Concept and design inspired from <strong>Wordle</strong> by <a href="https://twitter.com/powerlanguish" target="_blank">@powerlanguish</a> (Josh Wardle) and from <strong>Le Mot</strong> by <a href="https://twitter.com/louanben" target="_blank">@louanben</a> (Louan).
                                </p>
                                <p>
                                    For any request, contact <strong>@NonoDarko</strong> on Twitter, or by mail : <strong>arnaud.huynh@gmail.com</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </main>
    </div>
</template>

<script>
import * as seedrandom from 'seedrandom';
import moment from 'moment-timezone';

import LetterContainer from "./grid/LetterContainer.vue";
import Key from "./keyboard/Key.vue";
import words from "../assets/json/drawable-words.json";
import playableWords from "../assets/json/playable-words.json";
import * as definitions from "../assets/definitions.js";
import * as chinese from "../assets/chinese.js";

moment.locale('fr')
moment.tz.guess()

const NB_LETTERS = 6;
const NB_ATTEMPTS = 6;
const KEYBOARD_AZERTY = {
    name: 'azerty',
    content: [
        ['A', 'Z', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
        ['Q', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'M'],
        ['Entrer', 'W', 'X', 'C', 'V', 'B', 'N', 'Suppr'],
    ],
};
const KEYBOARD_QWERTY = {
    name: 'qwerty',
    content: [
        ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
        ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
        ['Entrer', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', 'Suppr'],
    ],
}
const KEYBOARD_QWERTZ = {
    name: 'qwertz',
    content: [
        ['Q', 'W', 'E', 'R', 'T', 'Z', 'U', 'I', 'O', 'P'],
        ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
        ['Entrer', 'Y', 'X', 'C', 'V', 'B', 'N', 'M', 'Suppr'],
    ]
};

let mapDefinitions = definitions.map;
let mapChineses = chinese.map;

export default {
    name: 'Game',
    components: {
        LetterContainer,
        Key,
    },
    data() {
        return {
            seedrandom,
            NB_LETTERS,
            NB_ATTEMPTS,
            KEYBOARD_AZERTY,
            KEYBOARD_QWERTY,
            KEYBOARD_QWERTZ,
            keyboard: KEYBOARD_AZERTY,
            today: moment(),
            words,
            mapDefinitions,
            mapChineses,
            attempts: [],
            results: [],
            currentAttempt: 1,
            countdownToNextWord: '',
            wordOfTheDay: '',
            error: '',
            correctLetters: [],
            partialLetters: [],
            incorrectLetters: ['F', 'Q', 'R', 'V', 'W', 'X'],
            won: false,
            finished: false,
            statsOpened: false,
            settingsOpened: false,
            helpOpened: false,
            colorBlindMode: false,
            sharedLink: true,
            animateLetter: true,
            bestAttemptPercent: 0,
            resultsCopied: false,
            userResults: {
                nbGames: 0,
                nbWins: 0,
                currentStreak: 0,
                bestStreak: 0,
                games: [],
            },
            // {
            //     nbGames: 0,
            //     nbWins: 0,
            //     currentStreak: 0,
            //     bestStreak: 0,
            //     games: [
            //         {
            //              date: '',
            //              won: false,
            //              nbAttempts: 6,
            //          }
            //     ],
            // }
        }
    },
    mounted() {

        // Update timer to next word
        setInterval((function () {
            let duration = moment.duration(this.today.clone().endOf('day').diff(moment()))
            this.countdownToNextWord = moment.utc(duration.as('milliseconds')).format('HH:mm:ss')
            if (duration.as('milliseconds') < 0)
                this.countdownToNextWord = '00:00:00'

        }).bind(this), 1000)

        window.addEventListener('keydown', event => {
            if (/^[a-zA-Z]$/.test(event.key)) {
                this.handleKeyClick(event.key.toUpperCase());
            } else if (event.key === 'Enter') {
                this.handleKeyClick('Entrer');
            } else if (event.key === 'Backspace') {
                this.handleKeyClick('Suppr');
            }
        });

        for (let i = 0; i < NB_ATTEMPTS; i++) {
            this.attempts.push([]);
            this.results.push(new Array(5));
        }
        this.getWordOfTheDay();
        this.getSavedData();

        if (localStorage.getItem('sharedLink')) {
            this.sharedLink = JSON.parse(localStorage.getItem('sharedLink'));
        }

        if (localStorage.getItem('colorBlindMode')) {
            this.colorBlindMode = JSON.parse(localStorage.getItem('colorBlindMode'));
        }

        if (localStorage.getItem('keyboard')) {
            this.keyboard = JSON.parse(localStorage.getItem('keyboard'));
        }
    },
    watch: {
        sharedLink() {
            localStorage.setItem('sharedLink', JSON.stringify(this.sharedLink));
        },
        colorBlindMode() {
            localStorage.setItem('colorBlindMode', JSON.stringify(this.colorBlindMode));
        },
        keyboard() {
            localStorage.setItem('keyboard', JSON.stringify(this.keyboard));
        },
    },
    methods: {
        async getWordOfTheDay() {

            const formatedDate = this.today.format('YYYY-M-D');
            const seed = seedrandom(formatedDate);
            const random = seed();

            const randomIndex = Math.floor((random * 1000) * (words.indexOf('BAIBAI') + 1));
            const indexOfTheWord = randomIndex <= words.length ? randomIndex : randomIndex - words.length;
            this.wordOfTheDay = words[indexOfTheWord];

            // Forcing temporaire pour éviter de changer le mot du jour de déploiement
            if (formatedDate === '2022-4-22')
                this.wordOfTheDay = 'GAOLAK'.split('').join('')
        },
        getSavedData() {
            if (localStorage.getItem('lastSave')) {
                const lastSave = localStorage.getItem('lastSave');
                if (lastSave === this.today.format('YYYY-M-D')) {
                    if (localStorage.getItem('attempts')) {
                        this.attempts = JSON.parse(localStorage.getItem('attempts'));
                    }
                    if (localStorage.getItem('results')) {
                        this.results = JSON.parse(localStorage.getItem('results'));
                    }
                    if (localStorage.getItem('currentAttempt')) {
                        this.currentAttempt = JSON.parse(localStorage.getItem('currentAttempt'));
                    }
                    if (localStorage.getItem('correctLetters')) {
                        this.correctLetters = JSON.parse(localStorage.getItem('correctLetters'));
                    }
                    if (localStorage.getItem('partialLetters')) {
                        this.partialLetters = JSON.parse(localStorage.getItem('partialLetters'));
                    }
                    if (localStorage.getItem('incorrectLetters')) {
                        this.incorrectLetters = JSON.parse(localStorage.getItem('incorrectLetters'));
                    }
                    if (localStorage.getItem('won')) {
                        this.won = JSON.parse(localStorage.getItem('won'));
                    }
                    if (localStorage.getItem('finished')) {
                        this.finished = JSON.parse(localStorage.getItem('finished'));
                    }
                    if (localStorage.getItem('userResults')) {
                        this.userResults = JSON.parse(localStorage.getItem('userResults'));
                    }
                } else {
                    this.reset();
                }
            } else {
                this.reset();
            }
        },
        reset() {
            localStorage.setItem('attempts', JSON.stringify(this.attempts));
            localStorage.setItem('results', JSON.stringify(this.results));
            localStorage.setItem('currentAttempt', JSON.stringify(this.currentAttempt));
            localStorage.setItem('correctLetters', JSON.stringify(this.correctLetters));
            localStorage.setItem('partialLetters', JSON.stringify(this.partialLetters));
            localStorage.setItem('incorrectLetters', JSON.stringify(this.incorrectLetters));
            localStorage.setItem('won', JSON.stringify(this.won));
            localStorage.setItem('finished', JSON.stringify(this.finished));
            localStorage.setItem('lastSave', this.today.format('YYYY-M-D'));
            if (localStorage.getItem('userResults')) {
                this.userResults = JSON.parse(localStorage.getItem('userResults'));
            }
        },
        getKeyColor(key) {
            if (this.correctLetters.includes(key)) {
                return 'correct';
            } else if (this.partialLetters.includes(key)) {
                return 'partial';
            } else if (this.incorrectLetters.includes(key)) {
                return 'incorrect';
            } else {
                return '';
            }
        },
        handleKeyClick(key) {
            this.animateLetter = true;
            this.error = '';
            if (key === 'Entrer') {
                this.verifyWord(this.attempts[this.currentAttempt - 1]);
            } else if (key === 'Suppr') {
                if(!this.userResults.games.find((game) => {
                    return game.date === this.today.format('YYYY-M-D');
                })) {
                    this.attempts[this.currentAttempt - 1].pop();
                }
            } else if (this.attempts[this.currentAttempt - 1].length < NB_LETTERS) {
                this.attempts[this.currentAttempt - 1].push(key);
            }
            localStorage.setItem('attempts', JSON.stringify(this.attempts));
        },
        verifyWord(attempt) {
            if (attempt.length === NB_LETTERS) {
                if (words.includes(attempt.join('')) || playableWords.includes(attempt.join(''))) {
                    this.verifyLetters(attempt);
                } else {
                    this.error = 'Ce mot n\'est pas dans la liste';
                    window.setTimeout(() => {
                        this.error = '';
                    }, 1000);
                }
            } else {
                this.error = 'Veuillez entrer un mot de ' + NB_LETTERS + ' lettres';
                window.setTimeout(() => {
                    this.error = '';
                }, 1000);
            }
        },
        verifyLetters(attempt) {
            let wordToGuess = this.wordOfTheDay.split('');
            let currentResult = this.results[this.currentAttempt - 1];

            attempt.forEach((letter, index) => {
                if (wordToGuess[index] === letter) {
                    currentResult[index] = 'correct';
                    wordToGuess[index] = '*';
                    if (!this.correctLetters.includes(letter)) {
                        this.correctLetters.push(letter);
                    }
                }
            });

            attempt.forEach((letter, index) => {
                if (currentResult[index] !== 'correct') {
                    if (wordToGuess.includes(letter)) {
                        let otherIndex = wordToGuess.indexOf(letter);
                        currentResult[index] = 'partial';
                        wordToGuess[otherIndex] = '*';
                        if (!this.partialLetters.includes(letter)) {
                            this.partialLetters.push(letter);
                        }
                    } else {
                        currentResult[index] = 'incorrect';
                        if (!this.incorrectLetters.includes(letter)) {
                            this.incorrectLetters.push(letter);
                        }
                    }
                }
            });
            localStorage.setItem('results', JSON.stringify(this.results));
            localStorage.setItem('correctLetters', JSON.stringify(this.correctLetters));
            localStorage.setItem('partialLetters', JSON.stringify(this.partialLetters));
            localStorage.setItem('incorrectLetters', JSON.stringify(this.incorrectLetters));
            if (attempt.join('') === this.wordOfTheDay) {
                this.won = true;
                this.finished = true;
                this.computeStats();
            } else {
                this.currentAttempt++;
                if (this.currentAttempt > NB_ATTEMPTS) {
                    this.finished = true;
                    this.computeStats();
                }
            }
            localStorage.setItem('currentAttempt', JSON.stringify(this.currentAttempt));
            localStorage.setItem('won', JSON.stringify(this.won));
            localStorage.setItem('finished', JSON.stringify(this.finished));
        },
        computeStats() {
            let games = this.userResults.games;
            let todaysGame = games.find((game) => game.date === this.today.format('YYYY-M-D'));

            if(!todaysGame) {
                let yesterday = this.today.clone().subtract(1, 'day');
                let yesterdaysGame = games.find(game => game.date === yesterday.format('YYYY-M-D'));
                let isStreak = Boolean(yesterdaysGame && yesterdaysGame.won);

                this.userResults.nbGames++;

                if (this.won) {
                    this.userResults.nbWins += 1;
                    this.userResults.currentStreak = isStreak ? this.userResults.currentStreak + 1 : 1;
                } else {
                    this.userResults.currentStreak = 0;
                }

                if (this.userResults.currentStreak > this.userResults.bestStreak) {
                    this.userResults.bestStreak = this.userResults.currentStreak;
                }
                games.push({
                    date: this.today.format('YYYY-M-D'),
                    won: this.won,
                    nbAttempts: this.currentAttempt,
                });

                localStorage.setItem('userResults', JSON.stringify(this.userResults));

                // Recalcul des streaks & victoires (temporaire)
                try {
                    let expected = games.reduce((ac, game, i) => {
                        let today = moment(game.date, 'YYYY-M-D');
                        let yesterday = today.clone().subtract(1, 'day');
                        let yesterdaysGame = games.slice(i - 1, i).find(g => g.date === yesterday.format('YYYY-M-D'));
                        // console.log('parse', game.date, today.format('YYYY-M-D'), yesterday.format('YYYY-M-D'), yesterdaysGame)
                        let isStreak = Boolean(yesterdaysGame && yesterdaysGame.won);
                        ac.nbGames++;
                        if (game.won) {
                            ac.nbWins += 1;
                            ac.currentStreak = isStreak ? ac.currentStreak + 1 : 1;
                        } else {
                            ac.currentStreak = 0;
                        }

                        if (ac.currentStreak > ac.bestStreak) {
                            ac.bestStreak = ac.currentStreak;
                        }

                        return ac;
                    }, {
                        nbGames: 0,
                        nbWins: 0,
                        currentStreak: 0,
                        bestStreak: 0
                    });

                    // console.log(expected, this.userResults);

                    let updated = false;
                    Object.entries(expected).filter(([k, v]) => this.userResults[k] !== v).forEach(([k, v]) => {
                        // console.log(`fixing "${k}" value, expected ${v}, got ${this.userResults[k]}`)
                        this.userResults[k] = v;
                        updated = true;
                    });

                    if (updated) {
                        localStorage.setItem('userResults', JSON.stringify(this.userResults));
                    }
                } catch (err) {
                    console.error(err);
                }

            }
            window.setTimeout(() => { this.statsOpened = true }, 2000);
        },
        getAttemptStat(attemptNumber) {
            let iteration = 0;
            this.userResults.games.forEach((game) => {
                if (game.nbAttempts === attemptNumber) {
                    iteration++;
                }
            });
            return iteration;
        },
        getAttemptStatPercent(attemptNumber) {
            if (this.getAttemptStat(attemptNumber) === 0) {
                return 0;
            }
            let attemptPercent = Math.round((this.getAttemptStat(attemptNumber) / this.userResults.nbGames) * 100);
            this.bestAttemptPercent = attemptPercent > this.bestAttemptPercent ? attemptPercent : this.bestAttemptPercent;
            return attemptPercent;
        },
        getWordID() {
            return this.today.clone().startOf('day').diff(moment("2022-01-30T00:00:00"), 'days') + 1
        },
        // Shitty function name to avoid shitty adblockers
        sh4reAntiAdblock() {
            const title = `Ue Dou (@WordleTeochew) #${this.getWordID()} ${this.currentAttempt <= NB_ATTEMPTS ? this.currentAttempt : '💀' }/${NB_ATTEMPTS}\n\n`;
            let schema = this.results.slice(0, this.currentAttempt).map((result) => {
                return result.map((letter) => {
                    if (letter === 'correct') {
                        return '🟩';
                    } else if (letter === 'partial') {
                        return '🟨';
                    } else {
                        return '⬛';
                    }
                }).join('');
            }).join('\n');
            const url = "https://uedou.jeunesteochew.com";

            let sharedContent = title + schema;

            if (this.sharedLink) {
                sharedContent = sharedContent + '\n\n' + url;
            }

            const errMsg = "Votre navigateur ne permet pas de copier du texte via un bouton. Une solution alternative sera proposée dans une prochaine mise à jour."
            if (!navigator.clipboard || typeof navigator.clipboard.writeText !== 'function') {
                alert(errMsg);
                return;
            }

            navigator.clipboard.writeText(sharedContent).then(() => {
                this.resultsCopied = true;
            }).catch(() => alert(errMsg));
        }
    }
}
</script>

<style lang="sass" scoped>
#game
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 100%
    overflow: hidden
    display: flex
    flex-direction: column
    background: #0E0E0F
    header
        width: 100%
        height: 60px
        display: flex
        justify-content: center
        background: #1D1D20
        @media (max-height: 540px)
            height: 40px
        .header-container
            max-width: 500px
            width: 100%
            height: 100%
            display: flex
            align-items: center
            justify-content: space-between
            @media (max-width: 512px)
                padding: 0 12px
                box-sizing: border-box
            .game-title
                display: flex
                align-items: center
                justify-content: center
                .letter
                    display: flex
                    align-items: center
                    justify-content: center
                    width: 24px
                    height: 24px
                    margin: 0 3px
                    font-size: 14px
                    font-weight: 700
                    border-radius: 4px
                    text-transform: uppercase
                    color: white
                    @media (max-height: 540px)
                        width: 20px
                        height: 20px
                        font-size: 12px
                        margin: 0 2px
                    @media (max-width: 320px)
                        width: 20px
                        height: 20px
                        font-size: 12px
                        margin: 0 1px
                    &.correct
                        background-color: #3EAA42
                    &.partial
                        background-color: #D3952A
                    &.incorrect
                        background-color: none
                        width: 14px
                .space
                    width: 14px
                    @media (max-width: 320px)
                        width: 10px
            .header-right
                display: flex
                width: 70px
                justify-content: space-between
                @media (max-height: 540px)
                    width: 62px
            .header-left
                width: 70px
            .icon-btn
                display: flex
                align-items: center
                justify-content: center
                width: 32px
                height: 32px
                background-color: #3A3A3C
                border-radius: 5px
                border-bottom: 2px solid #2B2B2B
                cursor: pointer
                transition: all .3s
                @media (max-height: 540px)
                    width: 28px
                    height: 28px
                &:hover
                    background-color: #474748
                    border-color: #313131
                &:active
                    background-color: #2B2B2B
                    border-color: #2B2B2B
                .icon
                    height: 13px
    main
        max-width: 500px
        height: 95%
        display: flex
        flex-direction: column
        align-items: center
        justify-content: space-between
        box-sizing: border-box
        position: relative
        .fade-enter-active, .fade-leave-active
            transition: opacity .5s
        .fade-enter, .fade-leave-to
            opacity: 0
        .fadeup-enter-active, .fade-leave-active
            transition: opacity .5s
        .fadeup-enter, .fade-leave-to
            opacity: 0
            transform: translateY(10px)
        .error
            top: 20px
            margin-top: 6px
            position: absolute
            background: #ebebeb
            color: #121213
            padding: 0.5em 1em
            border-radius: 0.5em
            font-size: 18px
            font-weight: bold
            z-index: 10
        .grid
            margin-top: auto
            margin-bottom: auto
            .attempt
                display: flex
                animation-duration: 0.3s
                perspective: 800px
                &.shake
                    animation-name: shake
        .keyboard
            margin-bottom: 20px
            @media (max-height: 900px)
                margin-bottom: 7px
            .keyboard-line
                margin-top: 10px
                &:first-child
                    margin: 0
                @media (max-height: 540px)
                    margin-top: 4px
        .help-modal
            position: fixed
            display: flex
            width: 100vw
            height: 100vh
            display: flex
            justify-content: center
            align-items: center
            background: rgba(0, 0, 0, 0.7)
            top: 0
            left: 0
            z-index: 10
            .help-modal-content
                position: relative
                display: flex
                align-items: flex-start
                flex-direction: column
                max-width: 450px
                width: 90%
                max-height: 90%
                box-sizing: border-box
                padding: 24px
                background: #1D1D20
                border-radius: 8px
                overflow-y: auto
                scrollbar-width: thin
                scrollbar-color: #d2d2d280 #fff0
                &::-webkit-scrollbar
                    -webkit-appearance: none
                    width: 4px
                &::-webkit-scrollbar-thumb
                    border-radius: 4px
                    background: rgba(0, 0, 0, 0.6)
                    &:hover
                        background: rgba(0, 0, 0, 1)

                .close-btn
                    position: absolute
                    top: 24px
                    right: 24px
                    display: flex
                    align-items: center
                    justify-content: center
                    width: 24px
                    height: 24px
                    background-color: #3A3A3C
                    border-radius: 5px
                    border-bottom: 2px solid #2B2B2B
                    cursor: pointer
                    transition: all .3s
                    &:hover
                        background-color: #474748
                        border-color: #313131
                        .icon
                            transform: rotate(90deg)
                    &:active
                        background-color: #2B2B2B
                        border-color: #2B2B2B
                    .icon
                        height: 10px
                        transition: all .3s
                h2
                    color: white
                    font-size: 20px
                    font-weight: 700
                    margin-bottom: 16px
                p
                    font-size: 14px
                    line-height: 1.3
                    margin-bottom: 12px
                    text-align: left
                    color: #8E8E90
                    &:last-child
                        margin-bottom: 0
                    a
                        color: white
                        text-decoration: none
                        &:hover
                            text-decoration: underline
                .help-exemple
                    .help-word
                        display: flex
                        margin-top: 24px
                        margin-bottom: 8px
                        .help-letter-container
                            width: 32px
                            height: 32px
                            border: 2px solid #646464
                            box-sizing: border-box
                            margin: 2px
                            border-radius: 4px
                            display: flex
                            align-items: center
                            justify-content: center
                            font-size: 16px
                            font-weight: bold
                            color: white
                            &.correct
                                border: none
                                background: #3EAA42
                                &.color-blind
                                    background: #F5793A
                            &.partial
                                border: none
                                background: #D3952A
                                &.color-blind
                                    background: #85C0F9
                            &.incorrect
                                border: none
                                background: #3A3A3C
                    p
                        span
                            font-weight: bold
                            &.correct
                                color: #3EAA42
                                &.color-blind
                                    color: #F5793A
                            &.partial
                                color: #D3952A
                                &.color-blind
                                    color: #85C0F9
        .endgame-modal
            position: fixed
            display: flex
            flex-direction: column
            width: 100vw
            height: 100vh
            display: flex
            justify-content: center
            align-items: center
            background: rgba(0, 0, 0, 0.7)
            top: 0
            left: 0
            z-index: 10
            .endgame-modal-content
                position: relative
                display: flex
                align-items: flex-start
                flex-direction: column
                max-width: 450px
                width: 90%
                max-height: 90%
                box-sizing: border-box
                padding: 24px
                background: #1D1D20
                border-radius: 8px
                overflow-y: auto
                scrollbar-width: thin
                scrollbar-color: #d2d2d280 #fff0
                &::-webkit-scrollbar
                    -webkit-appearance: none
                    width: 4px
                &::-webkit-scrollbar-thumb
                    border-radius: 4px
                    background: rgba(0, 0, 0, 0.6)
                    &:hover
                        background: rgba(0, 0, 0, 1)
                &.finished
                    border-bottom-left-radius: 0
                    border-bottom-right-radius: 0
                .close-btn
                    position: absolute
                    top: 24px
                    right: 24px
                    display: flex
                    align-items: center
                    justify-content: center
                    width: 24px
                    height: 24px
                    background-color: #3A3A3C
                    border-radius: 5px
                    border-bottom: 2px solid #2B2B2B
                    cursor: pointer
                    transition: all .3s
                    &:hover
                        background-color: #474748
                        border-color: #313131
                        .icon
                            transform: rotate(90deg)
                    &:active
                        background-color: #2B2B2B
                        border-color: #2B2B2B
                    .icon
                        height: 10px
                        transition: all .3s
                h2
                    color: white
                    font-size: 20px
                    font-weight: 700
                    margin-bottom: 16px
                .stats
                    margin-bottom: 32px
                    width: 100%
                    .stats-content
                        display: flex
                        @media (max-width: 380px)
                            flex-direction: column
                        .stats-line
                            display: flex
                            flex: 1
                            &:first-child
                                margin-right: 8px
                            @media (max-width: 380px)
                                &:first-child
                                    margin-right: 0px
                                &:first-child
                                    margin-bottom: 8px
                            .stats-item
                                display: flex
                                flex: 1
                                flex-direction: column
                                align-items: center
                                justify-content: center
                                height: 60px
                                margin-right: 8px
                                background-color: #0E0E0F
                                border-radius: 6px
                                &:last-child
                                    margin-right: 0
                                .stat-item-figure
                                    font-size: 22px
                                    font-weight: bold
                                    color: #3EAA42
                                .stat-item-label
                                    display: flex
                                    align-items: center
                                    height: 24px
                                    font-size: 12px
                                    color: white
                                    margin: 0 8px
                .graph
                    width: 100%
                    display: flex
                    flex-direction: column
                    .graph-content
                        width: 100%
                        display: flex
                        flex-direction: column
                        .graph-item
                            margin-top: 8px
                            width: 100%
                            display: flex
                            align-items: center
                            font-size: 12px
                            font-weight: 700
                            color: white
                            &:first-child
                                margin-top: 0
                            .attempt-number
                                width: 18px
                                display: flex
                                align-items: center
                            .attempt-skull
                                width: 18px
                                display: flex
                                align-items: center
                                .icon
                                    height: 10px
                            .attempt-stat
                                height: 17px
                                width: 100%
                                .attempt-bar
                                    height: 100%
                                    background: #3A3A3C
                                    color: white
                                    font-size: 10px
                                    font-weight: 400
                                    display: flex
                                    box-sizing: border-box
                                    padding: 0 6px
                                    justify-content: flex-end
                                    align-items: center
                                    min-width: 28px
                                    border-radius: 3px
                                    &.best
                                        background: #3EAA42
                .soluce
                    display: flex
                    flex-direction: column
                    margin-top: 16px
                    width: 100%
                    .download-link
                      display: flex
                      flex-direction: row
                      justify-content: center
                      margin-top: 16px
                      .download-btn
                        display: flex
                        align-items: center
                        justify-content: center
                        .btn
                          display: flex
                          align-items: center
                          justify-content: center
                          width: 40px
                          height: 40px
                          border-radius: 5px
                          margin: 0 4px
                          color: white
                          text-decoration: none
                          font-size: 14px
                          font-weight: 700
                          cursor: pointer
                          user-select: none
                          .icon
                            height: 14px
                            margin-right: 8px
                          &.definition-btn
                            background-color: #3EAA42
                            border-bottom: 2px solid #2B2B2B
                            &:hover
                              background-color: #44b848
                              border-color: #313131
                            &:active
                              background-color: #ffffff
                              border-color: #ffffff
                          // Shitty class name to avoid shitty adblocker
                          &.sh4re-btn-anti-adblock
                            background-color: #3EAA42
                            border-bottom: 2px solid #157D19
                            &:hover
                              background-color: #44b848
                              border-color: #1c9320
                            &:active
                              background-color: #157D19
                              border-color: #157D19
                      .download-icon
                        width: 30px
                        height: 30px
                        margin-left: 15px
                        margin-right: 15px
                        transition: all .3s
                      .download
                        width: 30px
                        height: 30px

                    .subtitle
                        font-size: 12px
                        font-weight: 700
                        color: rgba(255, 255, 255, 0.5)
                    h2
                        margin-bottom: 8px
                    .ctas
                        display: flex
                        align-items: center
                        justify-content: center
                        .btn
                            display: flex
                            align-items: center
                            justify-content: center
                            width: 108px
                            height: 36px
                            border-radius: 5px
                            margin: 0 4px
                            color: white
                            text-decoration: none
                            font-size: 14px
                            font-weight: 700
                            cursor: pointer
                            user-select: none
                            .icon
                                height: 14px
                                margin-right: 8px
                            &.definition-btn
                                background-color: #3A3A3C
                                border-bottom: 2px solid #2B2B2B
                                &:hover
                                    background-color: #474748
                                    border-color: #313131
                                &:active
                                    background-color: #2B2B2B
                                    border-color: #2B2B2B
                            // Shitty class name to avoid shitty adblocker
                            &.sh4re-btn-anti-adblock
                                background-color: #3EAA42
                                border-bottom: 2px solid #157D19
                                &:hover
                                    background-color: #44b848
                                    border-color: #1c9320
                                &:active
                                    background-color: #157D19
                                    border-color: #157D19
            .modal-footer
                display: flex
                flex-direction: column
                width: 100%
                justify-content: center
                align-items: center
                max-width: 450px
                width: 90%
                box-sizing: border-box
                padding: 12px
                background-color: #141415
                border-bottom-left-radius: 8px
                border-bottom-right-radius: 8px
                .next-in
                    display: flex
                    font-size: 12px
                    font-weight: 700
                    color: rgba(255, 255, 255, 0.5)
                    margin-bottom: 2px
                .time
                    font-size: 20px
                    font-weight: 700
                    color: white
        .settings-modal
            position: fixed
            display: flex
            flex-direction: column
            width: 100vw
            height: 100vh
            display: flex
            justify-content: center
            align-items: center
            background: rgba(0, 0, 0, 0.7)
            top: 0
            left: 0
            z-index: 10
            .settings-modal-content
                position: relative
                display: flex
                align-items: flex-start
                flex-direction: column
                max-width: 450px
                width: 90%
                box-sizing: border-box
                padding: 24px
                background: #1D1D20
                border-radius: 8px
                min-height: 420px
                max-height: 100%
                overflow-y: auto
                background: #121213
                border-radius: 8px
                overflow-y: auto
                scrollbar-width: thin
                scrollbar-color: #d2d2d280 #fff0
                &::-webkit-scrollbar
                    -webkit-appearance: none
                    width: 4px
                &::-webkit-scrollbar-thumb
                    border-radius: 4px
                    background: rgba(0, 0, 0, 0.6)
                    &:hover
                        background: rgba(0, 0, 0, 1)
                .close-btn
                    position: absolute
                    top: 24px
                    right: 24px
                    display: flex
                    align-items: center
                    justify-content: center
                    width: 24px
                    height: 24px
                    background-color: #3A3A3C
                    border-radius: 5px
                    border-bottom: 2px solid #2B2B2B
                    cursor: pointer
                    transition: all .3s
                    &:hover
                        background-color: #474748
                        border-color: #313131
                        .icon
                            transform: rotate(90deg)
                    &:active
                        background-color: #2B2B2B
                        border-color: #2B2B2B
                    .icon
                        height: 10px
                        transition: all .3s

                h2
                    color: white
                    font-size: 20px
                    font-weight: 700
                    margin-bottom: 16px
                h3
                    font-size: 14px
                    font-weight: 700
                    color: #8E8E90
                .settings-content
                    width: 100%
                    .settings-item
                        width: 100%
                        padding: 8px 0
                        &.setting-toggle
                            display: flex
                            justify-content: space-between
                            align-items: center
                            .toggle-button
                                background: #3A3A3C
                                width: 46px
                                height: 25px
                                border-radius: 100px
                                box-sizing: border-box
                                cursor: pointer
                                transition: all 0.3s
                                position: relative
                                .toggle
                                    background: white
                                    width: 17px
                                    height: 17px
                                    border-radius: 100px
                                    position: absolute
                                    left: 4px
                                    top: 4px
                                    transition: all 0.3s
                                &.activated
                                    background: #3EAA42
                                    .toggle
                                        transform: translateX(21px)
                        &.setting-button
                            display: flex
                            justify-content: space-between
                            align-items: center
                            .buttons
                                background: #3A3A3C
                                border-radius: 100px
                                height: 25px
                                button
                                    font-family: Outfit, Avenir, Helvetica, Arial, sans-serif
                                    height: 25px
                                    padding: 0
                                    width: 68px
                                    background: none
                                    cursor: pointer
                                    font-size: 11px
                                    font-weight: 700
                                    color: white
                                    border: none
                                    &.selected
                                        background: #3EAA42
                                        border-radius: 100px

                                @media (max-width: 360px)
                                    button
                                        width: 60px
                                        font-size: 10px
                                @media (max-width: 330px)
                                    height: 28px // Expend hitbox on height
                                    button
                                        width: 48px
                                        height: 28px
                                        font-size: 9px
                    .credits
                        h2
                            text-align: left
                            margin-top: 32px
                        p
                            font-size: 14px
                            line-height: 1.3
                            margin-bottom: 12px
                            text-align: left
                            color: #8E8E90
                            &:last-child
                                margin-bottom: 0
                            a
                                color: white
                                text-decoration: none
                                &:hover
                                    text-decoration: underline


@keyframes shake
    0%
        transform: translateX(0)
    20%
        transform: translateX(-10px)
    40%
        transform: translateX(10px)
    60%
        transform: translateX(-10px)
    80%
        transform: translateX(10px)
    100%
        transform: translateX(0)
</style>
