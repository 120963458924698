export const map = new Map();
map.set('BAIBAI', '拜拜');
map.set('JAIGIN', '再見');
map.set('JOISIA', '㩼謝');
map.set('GAMSIA', '感謝');
map.set('DUIMZU', '對唔住');
map.set('DUIMKI', '對唔起');
map.set('MOISEU', '孬意思');
map.set('JAPSAN', '十三');
map.set('JAPLAK', '十六');
map.set('JAPGAO', '十九');
map.set('SANJAP', '三十');
map.set('JEKBEH', '蜀百');
map.set('HUESEK', '灰色');
map.set('HUISEK', '灰色');
map.set('BEHSEK', '白色');
map.set('ANGSEK', '紅色');
map.set('GANSEK', '杆色');
map.set('LEKSEK', '綠色');
map.set('NAMSEK', '藍色');
map.set('GIMSEK', '金色');
map.set('SOGULA', '唆咕啦');
map.set('UANANG', '我人');
map.set('UAGAGI', '我家己');
map.set('BICHEU', '彼此');
map.set('DINANG', '底人');
map.set('MIHGAI', '乜個');
map.set('DIGIAN', '底囝');
map.set('HEUGAI', '許個');
map.set('JIGIAN', '只囝');
map.set('DODENG', '(在) ... 頂');
map.set('DOJION', '(在) ... 上');
map.set('DODANG', '(在) ... 中央');
map.set('DOGHUA', '(在) ... 外');
map.set('LAIDOI', '內底');
map.set('NGIANG', '癮');
map.set('UBIANG', '有變');
map.set('ENGGAI', '應該');
map.set('LONGSI', '攏是');
map.set('KAKKOU', '刻苦');
map.set('NGENSI', '硬是');
map.set('JOKJOK', '**');
map.set('JIAMDU', '漸堵');
map.set('HENGSI', '興死');
map.set('CHAIKI', '彩氣');
map.set('DAIKAI', '大概');
map.set('KOLENG', '可能');
map.set('SUGIAN', '須囝');
map.set('DAMBOH', '淡泊');
map.set('SIAMSU', '參須');
map.set('LANGLO', '零顆');
map.set('LANGSU', '零須');
map.set('CHAMDO', '差唔多');
map.set('YUGENG', '愈更 ...');
map.set('JIASEN', '者生，照生');
map.set('HIASEN', '遐生');
map.set('GAOJIU', '到 ... 就 ...');
map.set('DANGSI', '... 但是 ...');
map.set('BUKGUE', '... 不過 ...');
map.set('UIDIOH', '爲着 ...');
map.set('DEUHUI', '除非');
map.set('TAOTAO', '頭 ...頭 ...');
map.set('SIONME', '相罵');
map.set('BENION', '平樣');
map.set('BENADJ', '... 平 ADJ');
map.set('CHIANG', '淺');
map.set('HOKJAP', '複雜');
map.set('KIGUAI', '奇怪');
map.set('SIAOSI', '小時');
map.set('NODIAM', '兩點');
map.set('JEKYIK', '蜀日');
map.set('JEKMEN', '蜀暝');
map.set('YIKDAO', '日晝, 日昼');
map.set('LAHYIK', '歷日');
map.set('YIKLEH', '日歷');
map.set('SAILEH', '西曆');
map.set('JEKYIK', '蜀日');
map.set('CHENKI', '星期');
map.set('LOIBAI', '(禮)拜二');
map.set('CHENKI', '星(期)二');
map.set('GUEKEU', '過去');
map.set('TAOTAO', '頭頭');
map.set('INJAIN', '以前');
map.set('BHOIGU', '袂久');
map.set('JIJUNG', '只陣');
map.set('LIAOAO', '了後');
map.set('AOCHIU', '後手');
map.set('GIMYIK', '今日');
map.set('GAOGUA', '夠界');
map.set('MUEYIK', '每日');
map.set('GIMMEN', '今暝');
map.set('GEHYIK', '隔日');
map.set('GIMMEN', '今暝');
map.set('SIGANG', '時間');
map.set('SIJUNG', '時陣');
map.set('HEUJUA', '許匝');
map.set('JIOJUA', '上匝');
map.set('BAKJUA', '別匝');
map.set('CHIGAO', '遲到');
map.set('UANGAO', '晏到');
map.set('JAILAI', '再來');
map.set('LAIMHU', '來唔赴');
map.set('LAIMLI', '來唔戾');
map.set('JIAMSI', '暫時');
map.set('JONGGU', '總久');
map.set('PENGSI', '平時');
map.set('KUANNI', '款呢');
map.set('MENMEN', '猛猛');
map.set('JIAKSI', '即時');
map.set('GAKGEK', '結局');
map.set('JONGSU', '終須');
map.set('JIAKSI', '即時');
map.set('DANGSI', '同時');
map.set('MUEMUE', '妹妹');
map.set('TAITAI', '太太');
map.set('UAMIAN', '我名...');
map.set('BHOIMO', '袂孬');
map.set('TAKJEU', '讀書');
map.set('KEULAI', '去內');
map.set('BAIBAI', '拜拜');
map.set('JAIGIN', '再見');
map.set('GIANHO', '行好');
map.set('GAIIUA', '個是我...');
map.set('PENGIU', '朋友');
map.set('LIGHEU', '俚語');
map.set('DIOANG', '潮安');
map.set('DIOION', '潮陽');
map.set('GIKION', '揭陽');
map.set('HUILAI', '惠來');
map.set('EMEUNG', '廈門');
map.set('TAIBAK', '臺北');
map.set('SIUYEU', '首尔');
map.set('UAKNAM', '越南');
map.set('GIMTAH', '金塔');
map.set('TAIGOK', '泰國');
map.set('YIUHUK', '柔佛');
map.set('SOHLAI', '雪梨');
map.set('SAKMOA', '薩摩亞');
map.set('INGDOU', '印度');
map.set('HUIJIU', '非洲');
map.set('GIANAP', '加納');
map.set('NAMHUI', '南非');
map.set('HOLANG', '荷蘭');
map.set('BILISI', '比利時');
map.set('DEKGOK', '德國');
map.set('BEHLIM', '柏林');
map.set('SUISEU', '瑞士');
map.set('IDAILI', '意大利');
map.set('BOLANG', '波蘭');
map.set('NGOGOK', '俄國');
map.set('MUIGOK', '美國');
map.set('NIUIOH', '紐約');
map.set('NILENG', '年齡');
map.set('BHOIMO', '袂孬');
map.set('BHOIAN', '無影');
map.set('KAKSIK', '確實');
map.set('ANGHEA', '安呵啊');
map.set('UDAOLI', '有道理');
map.set('KOLENG', '可能');
map.set('DIGOOI', '底塊會');
map.set('GAISEU', '... 個事');
map.set('DOLONG', '多隆');
map.set('GOICHI', '街市');
map.set('MSIDAN', '唔是呾');
map.set('JIASEN', '者生');
map.set('KICHEU', '其次');
map.set('UASION', '我想');
map.set('UAHIMO', '我希望');
map.set('UATOIN', '我睇');
map.set('BHOGUE', '無怪');
map.set('GAODOI', '到底');
map.set('CHAIKI', '財氣');
map.set('NGENSI', '硬是');
map.set('UABAOU', '我飽了');
map.set('JOCHAI', '做菜');
map.set('MUIBHI', '美味');
map.set('AMLIAO', '飲料');
map.set('MUEHIA', '糜*');
map.set('HAIMIN', '海綿');
map.set('BANGDO', '方刀');
map.set('AHBHAH', '鴨肉');
map.set('BAIGUK', '排骨');
map.set('BHAHIN', '肉圓');
map.set('LENGHE', '龍蝦');
map.set('YIUHEU', '鰇魚');
map.set('HAIDUA', '海帶');
map.set('NAMGIO', '南薑');
map.set('GUCHAI', '韭菜');
map.set('LEKDAO', '綠豆');
map.set('DAOGHE', '豆芽');
map.set('DAOGHE', '豆芽');
map.set('KOUGUE', '苦瓜');
map.set('GAKGUE', '角瓜');
map.set('NAMGUE', '南瓜');
map.set('JUILAI', '水梨');
map.set('GIMGIK', '金桔');
map.set('NEKAIN', '肉眼');
map.set('LAHONG', '啦葒');
map.set('SAOMAO', '梢貓');
map.set('LIMKIM', '林檎');
map.set('LIMKIM', '林檎');
map.set('LIMKIM', '林檎');
map.set('ANGLAI', '紅梨');
map.set('TOKNOK', '秃*');
map.set('OUBHUE', '烏莓');
map.set('GAOLAK', '猴栗');
map.set('NONANG', '兩人');
map.set('JUCHAI', '主菜');
map.set('GOKLUI', '穀類');
map.set('SIOKAO', '燒烤');
map.set('GOISIK', '雞翅');
map.set('LOUGHO', '滷鵝');
map.set('GUEJAP', '粿汁');
map.set('BEHJUI', '白水');
map.set('SIOJUI', '燒水');
map.set('GIAHUI', '咖啡');
map.set('GANJAP', '柑汁');
map.set('BIKJIU', '啤酒');
map.set('SOGULA', '***');
map.set('HOUJIO', '胡椒');
map.set('OUJION', '烏醬');
map.set('HEUJUI', '魚水');
map.set('HEULOU', '魚滷');
map.set('HEJION', '蝦醬');
map.set('GIOJAP', '笳汁');
map.set('HIAJOI', '遐㩼');
map.set('BHISOU', '味素');
map.set('KAOGAM', '口感');
map.set('HOJIAH', '好食');
map.set('MOJIAH', '孬食');
map.set('HUJIOH', '麩質');
map.set('LAIKEU', '來去');
map.set('CHIGAO', '遲到');
map.set('UANGAO', '晏到');
map.set('INGDAO', '引導');
map.set('LIGIAN', '旅行');
map.set('GIANKI', '假期');
map.set('JUNGBI', '準備');
map.set('GAKBIK', '咭嗶');
map.set('GAKBAO', '咭暴');
map.set('UANJIN', '換錢');
map.set('PAKYIK', '曝日');
map.set('SIUJUI', '泅水');
map.set('BHIJUI', '沬水');
map.set('HAPIAN', '合影');
map.set('LOUBAI', '路牌');
map.set('UAJING', '我真 ...');
map.set('UABHOI', '我袂 ...');
map.set('DOUIAO', '肚枵');
map.set('HUANHI', '歡喜');
map.set('BAIDUI', '排隊');
map.set('SIUHUI', '收費');
map.set('SAIBAK', '西北');
map.set('PENTAO', '澎頭');
map.set('BHETAO', '碼頭');
map.set('DIETIH', '地下鐵');
map.set('LAIHUE', '來回');
map.set('KACHIA', '脚車');
map.set('DEKSEU', '的士');
map.set('PAHDEK', '拍的');
map.set('LOUGIN', '路舷');
map.set('GAKTAO', '角頭');
map.set('DAIDAO', '大道');
map.set('SUIDAO', '隧道');
map.set('YIPKAO', '入口');
map.set('LOUHUI', '路費');
map.set('GENGKI', '經氣');
map.set('DAOTOI', '倒替');
map.set('CHIAGI', '車技');
map.set('GOKLAI', '國內');
map.set('DENGGI', '登記');
map.set('SENGKI', '升起');
map.set('HENGLI', '行李');
map.set('GUEMEN', '過暝');
map.set('DENGGI', '登記');
map.set('UELANG', '畫廊');
map.set('HIONLI', '鄉里');
map.set('GEHION', '家鄉');
map.set('SIAOKU', '小區');
map.set('CHEUNG', '村');
map.set('SIUDOU', '首都');
map.set('IUDUAN', '郵單');
map.set('DENGGI', '戥機');
map.set('HUEHAH', '花瓣');
map.set('GAKHUE', '角花');
map.set('MUAKLI', '茉莉');
map.set('MIANNG', '麻秧');
map.set('GEKHUE', '菊花');
map.set('AICHAO', '野草');
map.set('GAODUA', '狗帶');
map.set('LOHTOH', '駱駝');
map.set('OUSENG', '烏猩');
map.set('NGIHEU', '義魚');
map.set('GAOBHO', '狗母');
map.set('GAOGOU', '狗牯');
map.set('JUIDEU', '水豬');
map.set('LAOGAO', '老猴');
map.set('KILING', '麒麟');
map.set('GOIANG', '雞翁');
map.set('GOIBHO', '雞母');
map.set('JUIGOI', '水雞');
map.set('BAGIAN', '峇囝');
map.set('TOUGAO', '塗猴');
map.set('CHAOME', '草蜢');
map.set('GAJUAH', '曱甴');
map.set('TOUUNG', '塗蚓');
map.set('KONGKI', '空氣');
map.set('HUEHAK', '化學');
map.set('DIJENG', '地震');
map.set('JUIJAI', '水灾');
map.set('KOIGIN', '溪舷');
map.set('HAIGIN', '海舷');
map.set('JUIGAO', '水沟');
map.set('SUANKA', '山脚');
map.set('SUAMOH', '沙漠');
map.set('JAPJAP', '汁汁');
map.set('HOUMUI', '雨微');
map.set('LOHHOU', '落雨');
map.set('LOHSOH', '落雪');
map.set('SIPDOU', '濕度');
map.set('UNGDOU', '温度');
map.set('IUCHAK', '油漆');
map.set('CHIUNI', '樹泥');
map.set('KIPCHU', '吸取');
map.set('YIKTAO', '日頭');
map.set('OUCHEN', '土星');
map.set('MAKPUE', '目皮');
map.set('GIHGAO', '頰交');
map.set('AOLENG', '喉嚨');
map.set('BAJIOH', '背脊');
map.set('YUBANG', '乳房');
map.set('BAKBOU', '腹部');
map.set('DOUJAI', '肚臍');
map.set('CHIUBI', '手臂');
map.set('DUATUI', '大腿');
map.set('KATAOU', '腳頭塢, 跤頭塢');
map.set('KANIAM', '腳廉, 跤廉');
map.set('KAJAIN', '腳指, 跤指');
map.set('TAOKAK', '頭殼');
map.set('PINSAI', '鼻屎');
map.set('HINSAI', '耳屎');
map.set('HAOSEN', '後生');
map.set('JABHOU', '查姆');
map.set('HAHMAK', 'mei');
map.set('CHUBHI', '趣味');
map.set('NANGMO', '人孬');
map.set('GAMMAO', '感冒');
map.set('LAOPIN', '流鼻');
map.set('SAKPIN', '塞鼻');
map.set('HIHPIN', '*鼻');
map.set('CHOHAO', '錯喉');
map.set('LAOSAI', '漏屎');
map.set('OUCHEN', '烏青');
map.set('HUNGMI', '昏迷');
map.set('JIKBEN', '疾病');
map.set('CHIDAI', '癡呆');
map.set('DONGOH', '倒愕');
map.set('HUIIAM', '肺炎');
map.set('HUILAO', '肺癆');
map.set('MAKHUE', '目花');
map.set('MAKJAM', '目針');
map.set('GINGSI', '近視');
map.set('SAKPIN', '塞鼻');
map.set('LAOPIN', '流鼻');
map.set('SENJUA', '生蛇');
map.set('YUNGAM', '乳癌');
map.set('JUIDAO', '水痘');
map.set('GUAIKA', '拐脚');
map.set('LAHTAO', '臘頭');
map.set('TAMHUE', '痰火');
map.set('GIOSEU', '急事');
map.set('LAIKUE', '內科');
map.set('UISENG', '醫生');
map.set('CHUKIN', '出院');
map.set('KUEHOK', '恢復');
map.set('TIGIAM', '體檢');
map.set('TIDANG', '體重');
map.set('TAOKUI', '敨氣');
map.set('SINGTI', '身體');
map.set('UESENG', '衛生');
map.set('SAIIOH', '西藥');
map.set('SIONSI', '傷勢');
map.set('BOUGIU', '補救');
map.set('UILIAO', '醫療');
map.set('KAOJAO', '口罩');
map.set('HUEJIU', '火酒');
map.set('BEHBOU', '白布');
map.set('PAHJAM', '拍針');
map.set('SOIBAO', '細胞');
map.set('IDAOSU', '胰島素');
map.set('EKMIAO', '疫苗');
map.set('SUHUEH', '輸血');
map.set('KAOSUA', '扣痧, 摳痧');
map.set('BOUGHE', '補牙');
map.set('SISUAN', '死產');
map.set('SENGAO', '性交');
map.set('BHELAO', '馬樓');
map.set('KIALAO', '企樓');
map.set('DANGSI', '東司');
map.set('UKBANG', '夗房');
map.set('HUESIK', '伙室');
map.set('DIESIK', '地下室');
map.set('LIMJIN', '臨簷');
map.set('HUEHNG', '花園');
map.set('TINJEN', '天井');
map.set('HUEJEN', '灰井');
map.set('TENGIN', '庭院');
map.set('HUECHU', '灰厝');
map.set('LAOTUI', '樓梯');
map.set('DITANG', '地毯');
map.set('DIBANG', '地板');
map.set('HOUJIA', '雨遮');
map.set('INDIAO', '椅條');
map.set('SAHUAK', '沙發');
map.set('CHEUNG', '床');
map.set('JEUGEH', '書格');
map.set('JIMJUI', '浸水');
map.set('UANTAI', '碗台');
map.set('POUGEH', '鋪架');
map.set('JIMTAO', '枕頭');
map.set('NAMJIM', '攬枕');
map.set('GAHPUE', '蓋被');
map.set('SAOCHU', '掃厝');
map.set('SIUSAO', '收掃');
map.set('SIUSIP', '收拾');
map.set('DOHDIK', '擇直');
map.set('BUNGGI', '糞萁');
map.set('SAOSIU', '掃菷');
map.set('DOBUNG', '地糞, 地坋');
map.set('NAHSAP', '垃圾');
map.set('EKGANG', '浴缸');
map.set('EKGENG', '浴巾');
map.set('BOMOGO', '保毛膏');
map.set('HINBEN', '耳鈀，耳筢，耳柄');
map.set('DUNGGO', '唇膏');
map.set('PANGIU', '芳油');
map.set('TICHIU', '剃鬚');
map.set('JANGEK', '*浴');
map.set('PAHPOK', '拍*');
map.set('OKCHIU', '握手');
map.set('KAPHIN', '闔耳');
map.set('BEJION', '耙痒');
map.set('GANGGU', '工具');
map.set('SIAKBI', '設備');
map.set('LAHJEK', '蠟燭');
map.set('TIHTUI', '鐵錘');
map.set('DENGPA', '灯泊');
map.set('MANGSE', '蠓紗');
map.set('SELONG', '紗籠');
map.set('NIHMAK', '痆目');
map.set('BEHMAK', '掰目');
map.set('HUAHHI', '喝*');
map.set('PAHEUH', '拍*');
map.set('GUAIKA', '拐腳');
map.set('JOKAKU', '坐腳軀');
map.set('SANKOU', '衫褲');
map.set('SOIBHE', '細碼');
map.set('DUABHE', '大碼');
map.set('CHIUNG', '手䘼');
map.set('BUESIM', '背心');
map.set('AMNIAN', '頷領');
map.set('PUELAO', '皮褸');
map.set('DUALAO', '大褸');
map.set('LAISAN', '內衫');
map.set('LAIKOU', '內褲');
map.set('YIOBOU', '尿布');
map.set('KOUDUA', '褲帶');
map.set('HINGAO', '耳鈎');
map.set('CHIUJI', '手子');
map.set('PAKDAN', '曝潐');
map.set('CHIGHI', '凄疑');
map.set('TINCHI', '天市');
map.set('KEHHOU', '客戶');
map.set('KEHHOU', '客');
map.set('INGKEH', '引客');
map.set('DOKUEH', '短缺');
map.set('GIAMGE', '減價');
map.set('BAIDUI', '排隊');
map.set('SEUNIO', '思量');
map.set('BAOSUE', '抱稅');
map.set('LAPSUE', '笠稅');
map.set('SAIJIN', '使錢');
map.set('JINPIO', '支票');
map.set('GOUGOI', '估計');
map.set('MAKTAO', '目頭');
map.set('MBEION', '唔平樣');
map.set('GAMDAK', '感值');
map.set('KAMDAK', '堪值');
map.set('LAKLOH', '力落');
map.set('SEKMAK', '識目');
map.set('DAHSIK', '踏實');
map.set('JEUSOI', '仔細');
map.set('LAOSIK', '老實');
map.set('BHOSEU', '無私');
map.set('SOUBAK', '**');
map.set('SUKDIK', '率直');
map.set('DANDUA', '膽大');
map.set('ULIANG', '有臉');
map.set('JEUHAO', '自豪');
map.set('BEHSAH', '白煠');
map.set('JEUSEU', '自私');
map.set('TAMSIM', '貪心');
map.set('KIGUAI', '奇怪');
map.set('AOCHAO', '嘔臭');
map.set('GEKSIM', '激心');
map.set('BHOENG', '無用');
map.set('LIGENG', '離經');
map.set('KOCHIO', '可笑');
map.set('UNGYIU', '溫柔');
map.set('DOKDEK', '獨特');
map.set('SUNGLI', '順利');
map.set('SINGKI', '新奇');
map.set('SIHENG', '時興');
map.set('JAOANG', '走紅');
map.set('SEKHAH', '適合');
map.set('TODANG', '妥當');
map.set('GAOUEH', '垢穫');
map.set('GIHGEK', '砌局');
map.set('UANGGE', '冤家');
map.set('BEHJEH', '掰債');
map.set('GHIHOK', '疑惑');
map.set('POIPEK', '批匹');
map.set('SIOJEN', '相爭');
map.set('DEKJUE', '得罪');
map.set('BOCHIU', '報仇');
map.set('JOHUAI', '做懷');
map.set('SIKBAI', '失敗');
map.set('BANGKI', '放棄');
map.set('DUMDUE', '堵唔綴');
map.set('IAPLAK', '壓力');
map.set('BHOSIM', '無心');
map.set('HUANHI', '歡喜');
map.set('SIMSEK', '心適');
map.set('BHONAI', '無奈');
map.set('SOKMOK', '寂寞');
map.set('KUIJIU', '愧疚');
map.set('YINGUI', '認爲');
map.set('KAOLEU', '考慮');
map.set('SEUKAO', '思考');
map.set('SIOSEK', '相識');
map.set('GAKDEK', '覺得');
map.set('GAMGAK', '感覺');
map.set('GEUJOH', '拒絕');
map.set('MGIDEK', '唔記得');
map.set('SIONKI', '想起');
map.set('TICHEN', '提醒');
map.set('GIATOH', '寄托');
map.set('PUEHUE', '詖話');
map.set('DAPENG', '答應');
map.set('SEUNIO', '廝量');
map.set('GAOLIU', '交流');
map.set('GOISEK', '解釋');
map.set('DANDIK', '呾直');
map.set('GAODAI', '交代');
map.set('UANBAI', '安排');
map.set('GOIUEH', '計劃');
map.set('YINGKO', '認可');
map.set('JIONGU', '照顧');
map.set('JINTIN', '支持');
map.set('BUEHOK', '佩服');
map.set('IANGMO', '羨慕');
map.set('JENCHU', '爭取');
map.set('GAKSOK', '結束');
map.set('TIGENG', '提供');
map.set('SIAOSI', '表示');
map.set('SIONBI', '相比');
map.set('SENGLI', '勝利');
map.set('KONGJI', '控制');
map.set('GAIYIP', '介入');
map.set('KIPING', '吸引');
map.set('TIGUIN', '提危');
map.set('UAKDEK', '获得');
map.set('TAMSOH', '探索');
map.set('DUKPUA', '突破');
map.set('JIAKAO', '借口');
map.set('GAMSIU', '感受');
map.set('IGIANG', '意見');
map.set('TAOTIN', '頭天');
map.set('UIHIAP', '威脅');
map.set('GIANUI', '行爲');
map.set('TAIDOU', '態度');
map.set('PIPIAH', '脾僻');
map.set('NAISIM', '耐心');
map.set('DAODEK', '道德');
map.set('MIANEU', '名譽');
map.set('JEHYIM', '責任');
map.set('BAOHOK', '包袱');
map.set('NGILAK', '毅力');
map.set('JAINTU', '前途');
map.set('SEUSEU', '私事');
map.set('KIHUNG', '氣氛');
map.set('HUNGUI', '氛圍');
map.set('SIANIM', '聲音');
map.set('LINIAM', '理念');
map.set('DIOLIU', '潮流');
map.set('IAOKIU', '要求');
map.set('MAKDEK', '目的');
map.set('JONGJI', '宗旨');
map.set('DUASEU', '大事');
map.set('IUDIAM', '優點');
map.set('ENGCHU', '用處');
map.set('GANGHU', '工夫');
map.set('HIHUAN', '喜歡');
map.set('NGIANG', '癮');
map.set('TAKPAH', '拓拍');
map.set('IOHHUE', '約會');
map.set('JIONGU', '照顧');
map.set('SIMSEU', '心事');
map.set('SIMLAI', '心內');
map.set('HOSIOH', '好惜');
map.set('SENGAM', '性感');
map.set('SIAOLI', '小禮');
map.set('GOUDAK', '孤獨');
map.set('HUAHAI', '和谐');
map.set('HAKHAO', '學校');
map.set('DAIHAK', '大學');
map.set('KUEJEU', '課書');
map.set('YIDIAM', '字典');
map.set('LAHBIK', '蜡筆');
map.set('SEKBIK', '色筆');
map.set('INGBIK', '鉛筆');
map.set('JEUBAO', '書包');
map.set('HAOHNG', '校園');
map.set('HOKSIP', '復習');
map.set('HAKLEH', '學歷');
map.set('HAKHUI', '學費');
map.set('HEGIAN', '夏假');
map.set('SEUYUI', '思維');
map.set('HAKJIA', '學者');
map.set('LAOSEU', '老師');
map.set('GAUANG', '教員');
map.set('TAKJEU', '讀書');
map.set('HAKSIP', '學習');
map.set('KAOCHI', '考試');
map.set('GIPGEH', '及格');
map.set('BANGHE', '放學');
map.set('KUIJEU', '開書');
map.set('UNGSIP', '溫習');
map.set('BOUSIP', '補習');
map.set('DOHKUE', '擇課');
map.set('LIUKIP', '留級');
map.set('LIUHAK', '留學');
map.set('SEKSEU', '碩士');
map.set('PAKSEU', '博士');
map.set('HAKKUE', '學科');
map.set('HUEHAK', '化學');
map.set('GHEKUE', '牙科');
map.set('DIJIOH', '地質');
map.set('KUEHAK', '科學');
map.set('SIAKUE', '社科');
map.set('SIKSIP', '實習');
map.set('GONGSI', '公司');
map.set('CHIANG', '厰');
map.set('HUENGI', '會議');
map.set('DAIHUE', '大會');
map.set('HOKBHU', '服務');
map.set('SIUYIP', '收入');
map.set('INHENG', '圓形');
map.set('SUIDEK', '垂直');
map.set('DEKYIK', '值日');
map.set('KUIHUE', '開會');
map.set('UANMEN', '宴暝');
map.set('BAGANG', '罷工');
map.set('TOHIUN', '退休');
map.set('JIONSI', '上司');
map.set('GENGLI', '經理');
map.set('JUSIAH', '主席');
map.set('LUKSEU', '律士');
map.set('UISENG', '醫生');
map.set('LAOSEU', '老師');
map.set('GAUANG', '教員');
map.set('JAKJIA', '作者');
map.set('LONGHU', '農夫');
map.set('JAKMAK', '節目, 扎目');
map.set('DIKBUA', '直播');
map.set('HITONG', '系統');
map.set('PINGMO', '屏幕');
map.set('GIILAK', '記憶力');
map.set('BENDAK', '病毒');
map.set('SIAOYI', '數字');
map.set('ANGNIU', '按鈕');
map.set('MANGJI', '網址');
map.set('JUHIAH', '主葉');
map.set('DOSUAN', '在綫');
map.set('KIMANG', '起網');
map.set('LISUAN', '離綫');
map.set('JUCHEH', '注冊');
map.set('JUSIAO', '注銷');
map.set('HIAJAI', '下載');
map.set('TIAPBA', '貼吧');
map.set('MIKBHE', '密碼');
map.set('MANGIU', '網友');
map.set('PAKKEH', '博客');
map.set('MUIPAK', '微博');
map.set('GEUJOH', '拒絕');
map.set('SIPING', '視頻');
map.set('GUENGI', '関機');
map.set('DENGGI', '重啓');
map.set('SAKDAK', '殺毒');
map.set('GIUSOI', '龜細');
map.set('HEKKEH', '黑客');
map.set('MANGIU', '網友');
map.set('ENGHOU', '用戶');
map.set('CHIUGI', '手機');
map.set('SINGHO', '信號');
map.set('DOSING', '短信');
map.set('SINGHO', 'dian');
map.set('JOISIA', '㩼謝');
map.set('GAMSIA', '感謝');
map.set('BAIBAI', '拜拜');
map.set('YIPJIN', '入錢');
map.set('KIAMJE', '欠債');
map.set('JINPIO', '支票');
map.set('HOUKAO', '戶口');
map.set('DAOJEU', '投資');
map.set('HUEJUK', '匯率');
map.set('EUHIAH', '余額');
map.set('BOUSUE', '補税');
map.set('LAPSUE', '笠税');
map.set('CHIANG', '*');
map.set('MOKSIU', '沒收');
map.set('GIMGAM', '禁監');
map.set('LUKSEU', '律士');
map.set('POUGIP', '普及');
map.set('JONGLI', '總理');
map.set('DOKLIP', '獨立');
map.set('JONANG', '做人');
map.set('ANGBAO', '紅包');
map.set('ANGJIA', '翁姐');
map.set('SENIOK', '生育');
map.set('JAIDUA', '臍帶');
map.set('LIHUNG', '離婚');
map.set('HISENG', '犧牲');
map.set('BOHIAM', '保險');
map.set('TOHIUN', '退休');
map.set('HOMIAN', '好命');
map.set('SENUAH', '生活');
map.set('PUNGMO', '墳幕');
map.set('PUNGDI', '墳地');
map.set('GUKHUE', '骨化');
map.set('GUEJUA', '過紙');
map.set('GOICHI', '街.市');
map.set('HINGDO', '現代');
map.set('BAOLAK', '暴力');
map.set('JENGDI', '政治');
map.set('DAOPIO', '投票');
map.set('GENGJI', '經濟');
map.set('HAOIAH', '效益');
map.set('MONANG', '孬人');
map.set('DUAPAO', '大炮');
map.set('JONGGA', '宗教');
map.set('IUGIAN', '遊行');
map.set('SENYIK', '生日');
map.set('HINSEU', '喜事');
map.set('JOJOIH', '做節');
map.set('JOKLEU', '祝汝 ...');
map.set('JOPUEH', '坐詖');
map.set('JUGIOH', '主角');
map.set('GUIJEK', '規則');
map.set('LIUGAO', '遛狗');
map.set('LOUIAN', '露營');
map.set('HOGIAN', '好聽');
map.set('UEDANG', '話筒');
map.set('IOGUNG', '搖滾');
map.set('MINGGO', '民歌');
map.set('GOCHEN', '歌星');
map.set('BHUDAO', '舞蹈');
map.set('HUNGGI', '薰支');
map.set('GIUDUI', '球隊');
map.set('PENGUI', '評委');
map.set('DAISAI', '大賽');
map.set('BIHUNG', '比分');
map.set('TAIGEK', '太極');
map.set('BHUSUK', '武術');
map.set('PAOBOU', '跑步');
map.set('JIPHAH', '集合');
map.set('PAHGIU', '拍球');
map.set('DOHGIU', '桌球');
map.set('UMOGIU', '羽毛球');
map.set('JOKGIU', '足球');
map.set('BAIGIU', '排球');
map.set('GUKSOH', '滑雪');
map.set('LIUSOH', '流雪');
map.set('SIUJUI', '泅水');
map.set('BHIJUI', '沕水');
map.set('NGEGEU', '硬舉');
map.set('HUOTEN', '俯卧撑');
map.set('JABHOU', '諸母，查姆');
map.set('GIANDI', '囝弟');
map.set('HAOSEN', '後生');
map.set('SIAONI', '少年');
map.set('CHENNI', '青年');
map.set('GOYING', '個人');
map.set('DONGNI', '中年');
map.set('OUNANG', '烏人');
map.set('GETENG', '家庭');
map.set('GENANG', '家人');
map.set('DAGUAN', '大官');
map.set('LAOPUA', '老婆');
map.set('SINGBU', '新伕');
map.set('HUGENG', '附近');
map.set('BOSENG', '波升');
map.set('GIOSEK', '茄色');
map.set('UANANG', '我人');
map.set('UANANG', '我人');
map.set('JIOSEN', '者生，照生');
map.set('HIOSEN', '遐生');
map.set('KAMGAI', '堪該');
map.set('GAOLAI', '... 到來');
map.set('HEUDOI', '許底');
map.set('BHUESI', '未是');
map.set('PUEPUE', '皮皮');
map.set('JONGGU', '總久');
map.set('DUDIOH', '堵着');
map.set('SENMAO', '生毛');
map.set('TOHTOH', '駝駝');
map.set('UANTUA', '宴拖');
map.set('HIASEN', '遐生');
map.set('DAIIAK', '大約');
map.set('IKHOIK', '一號一');
map.set('GOUTOU', '靠土');
map.set('DENGDO', '顛倒');
map.set('DENGDO', '顛倒');
map.set('JAKHIN', '*耳');
map.set('DINGDO', '鎮地');
map.set('GOHIAN', '各样');
map.set('SIALOI', '斜*.');
map.set('TANGJA', '*早');
map.set('JAJUNG', '早陣');
map.set('JOHYIK', '*日');
map.set('JONGGU', '總久');
map.set('DAKYIK', '逐日');
map.set('HEUHUE', '許回');
map.set('JIOHUE', '上回');
map.set('BAKHUE', '別回');
map.set('JICHEU', '只次');
map.set('LOHKEU', '落去');
map.set('JAMANG', '早慢');
map.set('IOGANG', '陶公');
map.set('SONGKA', '宋卡');
map.set('SEKDUK', '**');
map.set('BADANG', '**');
map.set('MILANG', '棉蘭');
map.set('BASIAN', '巴城');
map.set('HUSIAN', '府城');
map.set('SIAMLO', '暹羅');
map.set('YUIJIU', '維州');
map.set('TAHJIU', '塔州');
map.set('MAIDAN', '勿呾');
map.set('JAIDEK', '在得');
map.set('DIOHOU', '... 着了');
map.set('SIDIOH', '是着');
map.set('GOHLAI', '*來');
map.set('GALILO', '***');
map.set('SUABHO', '煞無');
map.set('GAOLAK', '够力');
map.set('GEGIAM', '加減');
map.set('MIHSEU', '乜事');
map.set('DANSIK', '呾實');
map.set('HOCHAI', '好彩');
map.set('CHAIKI', '財氣');
map.set('UALANG', '我浪');
map.set('JIUTAO', '就頭');
map.set('CHAMOI', '惨*');
map.set('HOJONI', '好怎呢');
map.set('MISUAN', '麵線');
map.set('BAKLAI', '腹內');
map.set('HOILIU', '蟹柳');
map.set('HEUPIO', '魚*');
map.set('BHUAGI', '磨蜞');
map.set('MUANLI', '鰻鱺');
map.set('HAIDAN', '海膽');
map.set('ANGHEU', '魟魚');
map.set('PANHOI', '奅蟹, 冇蟹');
map.set('BHEDAO', '馬豆');
map.set('NAIGAO', '蓮䕧');
map.set('GAKDAO', '角豆');
map.set('GIOBHO', '薑母');
map.set('LAOHAO', '老*');
map.set('SAOMAO', '**');
map.set('BAJIGU', '峇**');
map.set('OSUANG', '蠔璇, 蠔爽');
map.set('ONEUNG', '蠔卵');
map.set('HEUSEN', '魚生');
map.set('JUIGUE', '水粿');
map.set('CHAGUE', '炒粿');
map.set('GUEGAK', '粿角');
map.set('LABIAN', '朥餅');
map.set('KOBHAH', '炣肉');
map.set('DAUSUA', '豆沙');
map.set('GOISOK', '雞淑');
map.set('OUSIIU', '烏豉油');
map.set('GUEING', '粿印');
map.set('GANGUE', '酵粿');
map.set('SENGOU', '生菇');
map.set('HONGBU', '豐富');
map.set('GAOJUI', '夠水');
map.set('HIAMHO', '薟*');
map.set('GAOTIH', '高鐵');
map.set('IANUNG', '營運');
map.set('EUDIAN', '預定');
map.set('GACHIA', '挍車');
map.set('TOBANG', '退房');
map.set('BUELOH', '飛落');
map.set('HANGYU', '韩愈');
map.set('ENGHUE', '櫻花');
map.set('MUAKLI', '茉莉');
map.set('HUBALI', '花巴里');
map.set('LIAOGO', '鷯哥');
map.set('AMSUNG', '鹌鹑');
map.set('HOULIU', '胡溜');
map.set('SUAHEU', '鯊魚');
map.set('DAPDIH', '溚滴');
map.set('JUIENG', '水湧');
map.set('KOIKAM', '溪墈');
map.set('JENTIN', '晴天');
map.set('YIKMEN', '日猛');
map.set('YIKIAM', '日艳');
map.set('DAPDIH', '溚滴');
map.set('DOHHUE', '着火');
map.set('KUAHSI', '闊勢');
map.set('KIOKLA', '克拉');
map.set('YIKSIH', '日食');
map.set('TAOKAK', '頭殼');
map.set('DIHGAO', '頰交');
map.set('GAJIOH', '尻脊');
map.set('KABUAN', '腳盤, 跤盤');
map.set('GUJIAO', '龜鳥');
map.set('LANGPA', '卵脬');
map.set('NIBHAI', '膣屄');
map.set('GAPDUA', '合大');
map.set('PINGOI', '鼻膎');
map.set('GEKSAI', '結屎');
map.set('SENTUE', '生㿗');
map.set('PUYUAH', '浮熱');
map.set('MAKMOH', '目膜');
map.set('HUIIAM', '肺炎');
map.set('PUNANG', '浮膿');
map.set('SENJUA', '生蛇');
map.set('KOUBIK', '寇𤷗');
map.set('CHUIAN', '嘴揞');
map.set('JIPHAH', '集合');
map.set('BIBEGO', '枇杷膏');
map.set('BOUNAO', '補腦');
map.set('TOBUAN', '胎盤');
map.set('JUAKOU', '紙褲');
map.set('GHELAK', '牙力');
map.set('LIUGAM', '流感');
map.set('CHUHIA', '厝瓦');
map.set('PAIKEU', '歹去');
map.set('HUESIK', '火*');
map.set('JUNGIN', '伸椅');
map.set('TAMPUI', '痰呸');
map.set('LUAKMO', '擸毛, 捋毛');
map.set('SUAKKI', '漱齒');
map.set('SUEHKI', '刷齒');
map.set('GALAOH', '咖落');
map.set('TAOBOH', '黈薄');
map.set('DAOBAK', '兜縛');
map.set('KISUAK', '齒漱');
map.set('KISUEH', '齒刷');
map.set('BUKDAO', '抔斗');
map.set('BHIDAO', '米斗');
map.set('JAOKUI', '走開');
map.set('CHEUNG', '蹲');
map.set('PAHBHO', '拍無');
map.set('BHOGIN', '無見');
map.set('DUMING', '堵面');
map.set('DAKTAO', '*頭');
map.set('LAHDAH', '邋遢');
map.set('YICHIU', '二手');
map.set('SIOTIN', '相添');
map.set('DAKLAK', '哒叻');
map.set('UYIBOU', '有二步');
map.set('GUKLAK', '骨力');
map.set('JUDONG', '主動');
map.set('GANGLI', '講理');
map.set('UIHONG', '威風');
map.set('SUANGU', '山龜');
map.set('LAODAO', '潦倒');
map.set('GOUDAK', '孤獨');
map.set('DAKDIK', '**');
map.set('TUASEN', '拖生');
map.set('SEKJUI', '色水');
map.set('GANGJA', '奸詐');
map.set('GUEDIH', '**');
map.set('LAMUAN', '爛漫');
map.set('GOULAO', '孤牢');
map.set('CHOUKA', '湊巧');
map.set('CHIGEK', '刺激');
map.set('DOHHUE', '着火');
map.set('JIAMDU', '暫堵');
map.set('JIAOJO', '*做');
map.set('KOUDAK', '苦毒');
map.set('SIASUE', '*衰');
map.set('LAOKUI', '漏氣');
map.set('LANGDU', '浪堵');
map.set('UIHUAK', '違法');
map.set('DUMKEU', '堵唔去');
map.set('GAOLAO', '交摟');
map.set('GAOTAO', '猴頭');
map.set('GUESIM', '過心');
map.set('HUAHPU', '嘩譜');
map.set('SIASUE', '*衰');
map.set('BUANSI', '半死');
map.set('HIMHIM', '**');
map.set('GUESIM', '過心');
map.set('JAPJIP', '湁潗');
map.set('SIONDA', '想*');
map.set('GICHAK', '記切');
map.set('YIPMAK', '入目');
map.set('PISION', '譬像, 譬相');
map.set('JIAOUE', '鳥話');
map.set('DANGBO', '擔保');
map.set('GIGUAI', '記怪');
map.set('HUAKSI', '發誓');
map.set('LIMDEK', '理唔直');
map.set('JENCHU', '爭取');
map.set('SIOSIM', '小心');
map.set('DANDIK', '呾直');
map.set('BHODAI', '無*');
map.set('HIAPJO', '協助');
map.set('DONGSI', '重視');
map.set('BAONIA', '包領');
map.set('DAOBAK', '兜縛');
map.set('LIAOLI', '料理');
map.set('SIHSUH', '**');
map.set('SIODAO', '相斗');
map.set('GAKHAP', '結合');
map.set('JOJEKE', '做蜀下');
map.set('LANGUE', '浪話');
map.set('SUJIOH', '素質');
map.set('TAOTIN', '頭天');
map.set('CHAIKI', '財氣');
map.set('GAOSUA', '勾*');
map.set('DAODAH', '兜搭');
map.set('YIPGEH', '入格');
map.set('LIUJEU', '餾書');
map.set('GANGHU', '工夫');
map.set('GANGUI', '崗位');
map.set('HUNGSI', '粉絲');
map.set('ENGHUI', '用費');
map.set('HUELUI', '匯鐳');
map.set('BOUJUI', '補水');
map.set('OUGONG', '**');
map.set('AOCHAO', '漚臭');
map.set('GUILUK', '規律');
map.set('KANGDE', '擎茶');
map.set('NAMHNG', '男方');
map.set('GAOENG', '**');
map.set('DUACHU', '大厝');
map.set('BAIHUA', '排華');
map.set('IANGJO', '援助');
map.set('JOSIAN', '做聖');
map.set('SINGGA', '新教');
map.set('KUKLAK', '矻力');
map.set('DUAJIH', '大舌');
map.set('HIAOAK', '枭惡');
map.set('BOHGUE', '薄過草紙');
map.set('GATONG', '呷通');
map.set('KIABHE', '騎馬');
map.set('ENGHUE', '煙火');
map.set('PAHPAO', '拍炮');
map.set('GOCHIU', '歌手');
map.set('SIDIAO', '四掉');
map.set('HUILIM', '非林');
map.set('SIAJIN', '射箭');
map.set('AOCHAO', '漚臭');
map.set('PAMANG', '拋網');
map.set('SUKGAK', '摔角');
map.set('SIUJAI', '秀才');
map.set('IAOGUI', '枵鬼');
map.set('DOKMAK', '獨目');
map.set('CHILIU', '刺瘤');
map.set('PUSUNG', '浮笋');
map.set('MENANG', '暝安');
map.set('DAMSAM', '啖*');
map.set('GAJIAO', '胶焦');
map.set('BHODAK', '無咑');
map.set('DAHANG', '咑罕');
map.set('LAKSOU', '落蘇');
map.set('LOUHUE', '蘆薈');
map.set('JUDONG', '注重');
map.set('GEULIU', '居留');
map.set('BOCHIU', '报酬');
map.set('PUNANG', '浮人 ...');
map.set('KACHIU', '脚手');
map.set('BHEBHO', '馬母');
map.set('BINGLO', '槟榔');
map.set('HAOSUK', '效率');
map.set('GAOUEH', '垢穢');
map.set('SENGGE', '成家');
map.set('MENUAH', '暝*');
map.set('DODIAN', '倒*');
map.set('BHOKIU', '無求');
map.set('SOISIM', '細心');
