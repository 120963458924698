var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'has-letter': _vm.letter,
    validated: _vm.color,
    correct: _vm.color === 'correct',
    partial: _vm.color === 'partial',
    incorrect: _vm.color === 'incorrect',
    'no-animation': !_vm.animate,
    'color-blind': _vm.colorBlindMode,
},style:({ transitionDelay: !_vm.color ? '0s' : ((0.15 + 0.3 * (_vm.placement - 1)) + "s"), animationDelay: ((0.3 * (_vm.placement - 1)) + "s") }),attrs:{"id":"letter-container"}},[(_vm.letter)?_c('div',{staticClass:"letter",style:({ transitionDelay: ((0.15 + 0.3 * (_vm.placement - 1)) + "s")})},[_vm._v(" "+_vm._s(_vm.letter)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }